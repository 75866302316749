const theme = {
  colors: {
    testing: "#673FB4",
    primary: {
      default: "#03A6E1",
    },
    secondary: {
      default: "#27AE60",
    },
  },
  fonts: {
    body: "Helvetica', 'Arial', sans-serif",
  },
}

export default theme
