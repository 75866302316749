import React, { useState } from "react"
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons"
import { Box, Flex, IconButton, Link, VisuallyHidden } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { SocialLinks } from "./social-links"

const Logo = () => (
  <Link
    as={GatsbyLink}
    to="/"
    rel="home"
    maxWidth={{ lg: "120px", base: "98px" }}
  >
    <VisuallyHidden>Rainbow System</VisuallyHidden>
    <StaticImage src="../images/logo.png" alt="Rainbow Systems" />
  </Link>
)

const NavItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link
      activeClassName="active"
      as={GatsbyLink}
      borderBottom="2px solid transparent"
      margin="0 1.5rem"
      to={to}
      _hover={{
        borderColor: "white",
        textDecoration: "none",
      }}
    >
      <Box
        display="block"
        {...rest}
        sx={{
          ".active &": {
            borderBottom: "2px",
            borderColor: "primary.default",
          },
        }}
      >
        {children}
      </Box>
    </Link>
  )
}

const MenuToggle = ({ isOpen, onClick }) => (
  <Box>
    <IconButton
      bgColor="transparent"
      display={{ base: "block", lg: "none" }}
      onClick={onClick}
      variant="ghost"
    >
      {isOpen ? <CloseIcon /> : <HamburgerIcon />}
    </IconButton>
  </Box>
)

const Navigation = props => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Flex
      alignItems={{ lg: "center", base: "flex-start" }}
      color="white"
      justifyContent={{ lg: "strech", base: "space-between" }}
      margin="0 auto"
      maxWidth="1520px"
      padding="40px 20px"
      position="relative"
      wrap="wrap"
      bgColor={isOpen ? "blue.700" : "transparent"}
      {...props}
    >
      <Logo />
      <MenuToggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      <Flex
        alignItems="center"
        aria-label="Main"
        as="nav"
        display={{ lg: "flex", base: isOpen ? "block" : "none" }}
        justifyContent="center"
        margin="0 auto"
        role="navigation"
        width={{ lg: "auto", base: "full" }}
      >
        <NavItem onClick={() => setIsOpen(false)} to="/rainbow-srx">
          Rainbow SRX
        </NavItem>
        <NavItem onClick={() => setIsOpen(false)} to="/lisalaitteet">
          Lisälaitteet
        </NavItem>
        <NavItem onClick={() => setIsOpen(false)} to="/pesuaineet-ja-tuoksut">
          Pesuaineet ja tuoksut
        </NavItem>
        <NavItem onClick={() => setIsOpen(false)} to="/historia">
          Historia
        </NavItem>
        <NavItem onClick={() => setIsOpen(false)} to="/huolto-ja-tuki">
          Huolto ja tuki
        </NavItem>
      </Flex>
      <SocialLinks display={{ lg: "flex", base: isOpen ? "block" : "none" }} />
    </Flex>
  )
}

export default Navigation
