// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-huolto-ja-tuki-js": () => import("./../../../src/pages/huolto-ja-tuki.js" /* webpackChunkName: "component---src-pages-huolto-ja-tuki-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lisalaitteet-js": () => import("./../../../src/pages/lisalaitteet.js" /* webpackChunkName: "component---src-pages-lisalaitteet-js" */),
  "component---src-pages-pesuaineet-ja-tuoksut-js": () => import("./../../../src/pages/pesuaineet-ja-tuoksut.js" /* webpackChunkName: "component---src-pages-pesuaineet-ja-tuoksut-js" */),
  "component---src-pages-rainbow-srx-js": () => import("./../../../src/pages/rainbow-srx.js" /* webpackChunkName: "component---src-pages-rainbow-srx-js" */)
}

