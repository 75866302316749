import * as React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { ExternalLinkIcon } from "@chakra-ui/icons"
import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  Link,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react"

import backgroundWaves from "../images/background-waves.svg"

const Footer = () => (
  <>
    <Center
      bg="#E6F5FC"
      backgroundImage={`url('${backgroundWaves}')`}
      backgroundPosition="bottom"
      backgroundRepeat="no-repeat"
      backgroundSize="100% auto"
    >
      <VStack
        alignItems="flex-start"
        maxWidth="1345px"
        minHeight={{ base: "300px", md: "480px" }}
        width="100%"
        padding={10}
        paddingTop={{ base: 10, md: 20 }}
        paddingLeft={{ base: 10, md: 40 }}
        // justifyContent={{ base: "flex-start", md: "center" }}
      >
        <Box maxWidth="400px">
          <StaticImage
            alt="Rainbow SRX logo"
            layout="constrained"
            src="../images/rainbow-srx-logo.png"
          />
        </Box>
      </VStack>
    </Center>
    <Center as="footer" bg="blue.700" color="white" padding="40px 0">
      <Grid
        maxWidth="1345px"
        templateColumns={{ md: "repeat(4, 1fr)", base: "repeat(1 1fr)" }}
        marginLeft={{ base: "1rem" }}
        gap={10}
      >
        <GridItem colSpan={{ md: 2, base: 1 }} alignSelf="end">
          <Box as="section" spacing={10} marginTop="auto">
            <StaticImage src="../images/logo.png" alt="Rainbow Systems" />
            <Text fontSize="md" marginTop={3} maxWidth={400}>
              Vuodesta 1936 Rexair on kehittänyt ja tuottanut innovatiivisiä
              Rainbow tuotteita.
            </Text>
          </Box>
        </GridItem>
        <GridItem as="section">
          <Heading
            as="h3"
            fontSize="lg"
            textTransform="uppercase"
            color="blue.300"
            marginBottom={5}
          >
            Navigaatio
          </Heading>
          <List>
            <ListItem marginBottom={0}>
              <Link as={GatsbyLink} to="/rainbow-srx">
                Rainbow SRX
              </Link>
            </ListItem>
            <ListItem marginBottom={0}>
              <Link as={GatsbyLink} to="/lisalaitteet">
                Lisälaitteet
              </Link>
            </ListItem>
            <ListItem marginBottom={0}>
              <Link as={GatsbyLink} to="/pesuaineet-ja-tuoksut">
                Pesuaineet ja tuoksut
              </Link>
            </ListItem>
            <ListItem marginBottom={0}>
              <Link as={GatsbyLink} to="/historia">
                Historia
              </Link>
            </ListItem>
            <ListItem marginBottom={0}>
              <Link as={GatsbyLink} to="/huolto-ja-tuki">
                Huolto ja tuki
              </Link>
            </ListItem>
          </List>
        </GridItem>
        <GridItem as="section">
          <Heading
            as="h3"
            fontSize="lg"
            textTransform="uppercase"
            color="blue.300"
            marginBottom={5}
          >
            Yhteystiedot
          </Heading>
          <Text>Thermo Care Oy / Rainbowsystems</Text>
          <Text>Kellonkärki 8</Text>
          <Text>70460 Kuopio</Text>
          <Link
            color="blue.300"
            href="https://maps.google.com/maps?ll=62.924962,27.718008&z=16&t=m&hl=fi&gl=US&mapclient=embed&cid=3087331211276752030"
            isExternal
          >
            Avaa osoite kartalla <ExternalLinkIcon mb="4px" />
          </Link>
          <Text>
            Puh.{" "}
            <Link href="tel:+358 50 5663765" _hover={{ color: "blue.300" }}>
              +358 50 5663765
            </Link>
          </Text>
        </GridItem>
      </Grid>
    </Center>
  </>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
