import * as React from "react"
import PropTypes from "prop-types"
import { Box } from "@chakra-ui/react"

import Navigation from "./navigation"

const Header = ({ siteTitle }) => (
  <Box as="header" position="absolute" width="100%" zIndex="15">
    <Navigation />
  </Box>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
