import React from "react"
import { Box, Icon, Link, VisuallyHidden } from "@chakra-ui/react"
import { FaFacebook } from "react-icons/fa"

export const SocialLink = ({ href, icon, label, size = 8, ...props }) => (
  <Box {...props}>
    <Link href={href}>
      <Icon as={icon} boxSize={size} _hover={{ color: "blue.500" }} />
      <VisuallyHidden>{label}</VisuallyHidden>
    </Link>
  </Box>
)

export const SocialLinks = props => (
  <SocialLink
    href="https://www.facebook.com/Rainbowsystems-276388416454"
    icon={FaFacebook}
    label="Rainbowsystems"
    {...props}
  />
)

export default SocialLinks
