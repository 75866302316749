/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import { ChakraProvider, CSSReset, extendTheme } from "@chakra-ui/react"

import Layout from "./src/components/layout"
import theme from "./src/components/theme"

export const wrapRootElement = ({ element }) => (
  <ChakraProvider theme={extendTheme(theme)}>
    <CSSReset />
    <Layout>{element}</Layout>
  </ChakraProvider>
)
